$primaryColor: #3180cf; // primary color for all components
$linkColor: #3180cf; // link color
$successColor: #52c41a; // success state color
$warningColor: #ffcb02; // warning state color
$errorColor: #ff0000; // error state color

$darkGrayColor: #323643;
$grayColor: #606470;
$lightGrayColor: #f8fbfc;

$onlineColor: #10ca88;
$offlineColor: #9b9b9b;

$primaryFadeColor: rgba($primaryColor, 0.1);

$menuColor: #3080ce;
$menuSelectColor: #0960ba;
$menuFontColor: #fff;
$menuFontSelectColor: $lightGrayColor;
$menuFontDisableColor: $grayColor;
$menuSelectorColor: $primaryColor;
$menuSelectorBorderColor: $primaryColor;
$menuLinkColor: #fff;

$themeBackground: #F5F6FB;

:export {
  primaryColor: $primaryColor;
  linkColor: $linkColor;
  successColor: $successColor;
  warningColor: $warningColor;
  errorColor: $errorColor;
  darkGrayColor: $darkGrayColor;
  grayColor: $grayColor;
  lightGrayColor: $lightGrayColor;

  onlineColor: $onlineColor;
  offlineColor: $offlineColor;

  primaryFadeColor: $primaryFadeColor;

  menuColor: $menuColor;
  menuSelectColor: $menuSelectColor;
  menuFontColor: $menuFontColor;
  menuFontSelectColor: $menuFontSelectColor;
  menuFontDisableColor: $menuFontDisableColor;
  menuSelectorBorderColor: $menuSelectorBorderColor;
  menuSelectorColor: $menuSelectorColor;
  menuLinkColor: $menuLinkColor;

  themeBackground: $themeBackground;
}
